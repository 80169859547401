import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/contactLayout';
import Call from '../../components/Call';
import ReactJotformEmbed from 'react-jotform-embed';

const Contact = (props) => (
  <Layout bodyClass="page-contact contact-page">
    <SEO
      title="Contact LaSalle For Heating, Furnace, AC or Ductwork Intallation, Repair, or Service"
      description="Schedule an appointment 24/7 for Heating and Cooling service, repair, or installation"
    />
    <section className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
      <div className="text-center">
        <p className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block xl:inline">We Are Ready</span>{' '}
          <span className="block text-red-600 xl:inline">To Help</span>
        </p>
        <h1 className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Schedule your HVAC service, repair, or installation using the form
          below.
        </h1>
      </div>
    </section>
    <div className="mx-auto">
      <div>
        <ReactJotformEmbed src="https://form.jotform.com/211227431380041" />
      </div>
    </div>
  </Layout>
);

export default Contact;
